@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body {
  background-color: #eee;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.light-login {
  background: url(../images/login-background-gate.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-container {
  width: 45%;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  border-left: 2px solid #013a6f;
  padding: 15px 0 15px 15px;
  position: relative;
}

h3 a.inline_help_link {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: inline-block;
  vertical-align: top;
  line-height: 24px;
}

h3 a.inline_help_link i {
  color: #013a6f;
  vertical-align: top;
  font-weight: 400;
}

h3 a.inline_help_link:hover i {
  font-weight: 900;
}

h4.titolo_subgrid {
  margin-left: 20px;
  margin-bottom: -20px;
}

a {
  color: #013a6f;
}

a:hover {
  color: #013a6f;
  text-decoration: underline;
}

#gritter-notice-wrapper {
  background-color: rgba(11, 127, 189, 0.9);
}

#gritter-notice-wrapper .gritter-item-wrapper {
  margin: 0;
}

#gritter-notice-wrapper .gritter-warning {
  background-color: rgba(222, 185, 20, 0.9);
}

#gritter-notice-wrapper .gritter-success {
  background-color: rgba(0, 163, 57, 0.9);
}

#gritter-notice-wrapper .gritter-error {
  background-color: rgba(189, 4, 50, 0.9);
}

.red {
  color: #a94442;
}

.grey {
  color: darkgrey;
}

.text-primary {
  color: #013a6f;
}

.btn-primary {
  background: #013a6f;
}

.btn-grey {
  background-color: gray;
  color: #fff;
}

.btn-grey:hover {
  color: #fff;
  background-color: #646464;
}

.custom-fieldset {
  padding: 15px;
  border: 1px solid #286090;
}

.custom-legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 20px;
  width: auto;
  border: 1px solid #286090;
}

.select2-container--default .select2-selection .select2-selection__clear {
  right: 0;
  color: #a94442;
}

.select2-dropdown.select2-dropdown--above {
  min-width: 200px;
}

button.am_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

[v-cloak] {
  display: none;
}

.container-fluid {
  margin-bottom: 20px;
}

.dropzone .dz-preview .dz-progress {
  display: none;
}

.pre-header {
  background: #eee;
  font-size: 16px;
  color: #013a6f;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pre-header small {
  font-size: 12px;
}

.pre-header a {
  color: #013a6f;
}

.pre-header .select2-container--default .select2-selection--single {
  background-color: transparent;
}

.pre-header #menu_login_wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.pre-header #menu_login_wrapper button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  vertical-align: middle;
  font-size: 1em;
}

.pre-header #menu_login_wrapper button span.menu_login_icona {
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #013a6f;
  margin-right: 5px;
  border-radius: 20px;
  font-size: 1.3em;
}

.pre-header #menu_login_wrapper button span.menu_login_icona .fa-user-circle {
  display: inline-block;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.87);
}

.pre-header #menu_login_wrapper .dropdown-menu {
  top: -5px;
  margin-top: 0;
}

.pre-header #menu_login_wrapper span.login_nome {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #013a6f;
  font-weight: bold;
}

.pre-header select {
  margin-right: 10px;
  padding: 0 0 0 5px;
  font-size: 16px;
  line-height: 1;
  border-radius: 5px;
  border: 1px solid #013a6f;
  height: 25px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right transparent;
  -webkit-appearance: none;
  color: #013a6f;
}

.header {
  background: #013a6f;
  padding: 25px;
  margin-top: 0;
  margin-bottom: 0;
}

.header .logo {
  float: left;
  width: 33%;
}

.header .logo img {
  height: 60px;
}

.header .title {
  float: left;
  width: 33%;
  text-align: center;
  color: white;
  padding: 15px 0;
  font-size: 20px;
  font-style: italic;
}

.header .brand {
  float: left;
  text-align: right;
  width: 33%;
}

.header .brand img {
  height: 60px;
}

footer.portal-main-footer {
  position: fixed;
  z-index: 10;
  width: 100%;
  line-height: 32px;
  height: 32px;
  bottom: 0px;
  background-color: #424242;
  opacity: 0.8;
  color: white;
  font-size: 12px;
}

footer.portal-main-footer a {
  color: white;
}

.menu {
  background: white;
  border: 1px solid #ccc;
  padding: 15px;
}

.menu .menu-image {
  position: relative;
}

.menu .menu-image img {
  width: 100%;
}

.menu .menu-image .menu-brand {
  width: 100%;
  bottom: 0;
  padding: 10px;
  text-align: center;
  float: none;
}

.menu .menu-image .menu-brand ::after {
  content: '';
  display: block;
  clear: both;
}

.menu .menu-image .menu-brand img {
  width: 150px;
}

.menu .menu-image .menu-brand .navbar-toggle {
  display: none;
  margin: 0;
  border: 2px solid #fff;
}

.menu .menu-image .menu-brand .navbar-toggle .icon-bar {
  background-color: #fff;
}

.menu .nav {
  margin-top: 10px;
}

.menu .nav li a {
  transition: all 0.5s ease;
  display: block;
  background: #eee;
  color: #666;
  margin: 2.5px 0;
  padding: 5px 5px;
  font-size: 16px;
}

.menu .nav li a i {
  width: 22px !important;
  text-align: center;
}

.menu .nav li ul {
  list-style-type: none;
}

.menu .nav li.active a, .menu .nav li:hover a {
  text-decoration: none;
  color: #666;
  background: #ddd;
}

@media screen and (min-width: 768px) {
  .menu .nav.collapse {
    display: block;
    height: auto !important;
  }
}

@media screen and (max-width: 767px) {
  .menu {
    margin: 12px;
  }
  .menu .menu-image .menu-brand img {
    float: left;
    margin-top: 8px;
  }
  .menu .menu-image .menu-brand .navbar-toggle {
    display: block;
  }
}

.tab-pane.bordered {
  padding: 12px;
  border: 1px solid #013a6f;
  border-radius: 5px;
}

.content {
  margin-top: 25px;
}

.content p {
  margin: 10px 0;
}

.content hr {
  border-top: 1px solid #ccc;
}

.content .content-box {
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 25px;
}

.content .content-box h3 {
  margin: -10px 0 10px -10px;
}

.content .content-box .description {
  padding: 5px;
  background: #eee;
}

.content .content-box ul:not(.nav) {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}

.content .content-box ul:not(.nav) li {
  margin: 5px 0 5px 3px;
}

.content .content-box ul:not(.nav) li i {
  color: #013a6f;
  margin-right: 10px;
}

.content .content-box.sidebar img {
  width: 100%;
  margin: 5px 0;
}

.content .content-box.news-list .description {
  margin: -10px -10px 10px -10px !important;
  padding: 10px;
}

.content .content-box.news-list .news-media {
  text-align: center;
  border: 1px solid #eee;
  padding: 10px;
}

.content .content-box.news-list .news-media i {
  display: block;
  font-size: 40px;
  margin-bottom: 10px;
}

.content .content-box.news-list .news-media img {
  max-width: 100%;
  height: 70px;
}

.content .content-box.download-list #download-files-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.content .content-box.download-list #download-files-container .download-media {
  text-align: center;
  border: 1px solid #eee;
  padding: 10px;
  margin-right: -1px;
  margin-bottom: -1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.content .content-box.download-list #download-files-container .download-media i {
  font-size: 40px;
  margin-bottom: 5px;
}

.content .content-box.download-list #download-files-container .download-media img {
  display: block;
  max-width: 100%;
  height: 70px;
  margin-bottom: 5px;
}

.content .content-box.download-list #download-files-container .download-media div {
  width: 100%;
  word-break: break-all;
}

@media screen and (max-width: 1200px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
}

.form-fields {
  padding: 15px 0;
}

.form-fields .form-field, .form-fields .form-group {
  margin: 15px 0;
}

.form-fields .form-field .select2-container, .form-fields .form-group .select2-container {
  height: auto !important;
  line-height: unset !important;
}

.form-fields .form-field .select2-container .select2-selection select2-selection__rendered, .form-fields .form-group .select2-container .select2-selection select2-selection__rendered {
  line-height: unset;
}

.form-fields .form-field .select2-container .select2-selection .select2-selection__arrow, .form-fields .form-group .select2-container .select2-selection .select2-selection__arrow {
  height: 100%;
}

.form-fields .form-field input:not([type=checkbox]):not(.am-input):not(.select2-search__field), .form-fields .form-field textarea:not(.am-input):not(.select2-search__field), .form-fields .form-field select:not(.am-input):not(.select2-search__field), .form-fields .form-field .select2-container--default .select2-selection--single:not(.am-input):not(.select2-search__field), .form-fields .form-group input:not([type=checkbox]):not(.am-input):not(.select2-search__field), .form-fields .form-group textarea:not(.am-input):not(.select2-search__field), .form-fields .form-group select:not(.am-input):not(.select2-search__field), .form-fields .form-group .select2-container--default .select2-selection--single:not(.am-input):not(.select2-search__field) {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #013a6f;
  min-height: auto;
  line-height: 1.4;
}

.form-fields .form-field input:not([type=checkbox]):not(.am-input):not(.select2-search__field), .form-fields .form-field select:not(.am-input):not(.select2-search__field), .form-fields .form-field .select2-container--default .select2-selection--single:not(.am-input):not(.select2-search__field), .form-fields .form-group input:not([type=checkbox]):not(.am-input):not(.select2-search__field), .form-fields .form-group select:not(.am-input):not(.select2-search__field), .form-fields .form-group .select2-container--default .select2-selection--single:not(.am-input):not(.select2-search__field) {
  height: 31px !important;
}

.form-fields .form-field .control-label, .form-fields .form-group .control-label {
  font-size: 14px;
  text-align: right;
}

.form-fields .am-form-field {
  margin: 15px 0 15px 0;
}

.form-fields .form-group::after {
  content: '';
  display: block;
  clear: both;
}

.filter_form_container {
  border: 1px solid #ddd;
  padding: 15px;
}

.filter_form_container .row .col-xs-12 {
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .pre-header > div {
    text-align: center;
    margin: 5px 0;
  }
}

@media screen and (max-width: 560px) {
  .header .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .header .brand {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .header .logo {
    width: 100%;
    text-align: center;
  }
  .header .title {
    width: 100%;
    text-align: center;
  }
  .header .brand {
    width: 100%;
    text-align: center;
  }
}

table thead th input {
  border: 1px solid #aaa !important;
  border-radius: 4px;
  width: calc(100% + 6px) !important;
  box-sizing: border-box;
  height: 25px !important;
  margin: 2px -3px 0 -3px;
  padding: 5px 2px 5px 2px !important;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: calc(100%);
  background-color: transparent;
  margin-bottom: 7px;
}

.table thead {
  background-color: #F2F2F2;
  background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
}

.table thead tr {
  background-color: transparent !important;
}

.table thead tr th {
  color: #23527c;
  padding: 5px;
  vertical-align: bottom;
  border: 1px solid #ddd !important;
  font-size: 12px;
}

.table thead tr th.text-right {
  text-align: left;
}

.table thead tr th input {
  font-size: 12px;
}

.table thead tr:last-child th {
  border-bottom-width: 2px;
}

.table thead tr:last-child th:not(:last-child) {
  /*border-right:1px solid #ddd !important;*/
}

.table tbody tr {
  background-color: #fff;
}

.table tbody tr:nth-of-type(2n+1) {
  background-color: #f9f9f9;
}

.table tbody tr td {
  border: 1px solid #ddd;
  padding: 3px 5px 0px 5px;
  font-size: 11px;
}

.table tbody tr td.text-right {
  text-align: left;
}

.table tbody tr td img {
  width: 100%;
  max-width: 300px;
}

.table tbody tr td .dtImgPreview {
  display: inline-block;
  vertical-align: top;
  width: 90px;
  height: 90px;
  margin: 3px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.table tbody tr td .dtColorPreview {
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.table tbody tr:hover {
  background-color: #F2F2F2;
}

.table tbody tr.selected {
  background-color: #e1ffa6;
}

.table tbody tr .btn.btn-link {
  padding: 1px !important;
}

.dataTables_wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

.dataTables_wrapper .row:first-child .dt-buttons {
  text-align: right;
  margin: 0;
  padding: 5px 7px 5px 7px;
}

.dataTables_wrapper .dataTables_length .select2-selection {
  border-color: #ccc;
}

.dataTables_wrapper .dataTables_length .select2-container, .dataTables_wrapper .dataTables_length .select2-selection, .dataTables_wrapper .dataTables_length .select2-selection__arrow, .dataTables_wrapper .dataTables_length .select2-selection__rendered {
  height: 34px !important;
  line-height: 34px !important;
}

.dataTables_wrapper .dataTables_paginate {
  text-align: right;
}

.dataTables_wrapper .dataTables_paginate ul.pagination li.paginate_button select.paginate_select {
  width: auto !important;
  height: auto !important;
  appearance: none  !important;
  padding: 6px 12px  !important;
  line-height: 1.42857143  !important;
  font-size: 14px  !important;
  text-decoration: none  !important;
  color: #337ab7  !important;
  background-color: #fff  !important;
  border: 1px solid #ddd  !important;
  margin-left: -1px  !important;
  border-radius: 0  !important;
}

.dataTables_wrapper .dataTables_paginate ul.pagination li.paginate_button span.paginate_total {
  border: 1px solid transparent !important;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: 5px;
}

.nav-tabs li {
  white-space: nowrap;
}

.nav-tabs li.active a, .nav-tabs li.active a:focus, .nav-tabs li.active a:hover {
  border-color: #013a6f;
  border-bottom-color: #fff;
  z-index: 2;
}

.nav-tabs li > span {
  display: inline-block;
  padding: 5px 15px;
}

.nav-tabs li > span select {
  display: inline-block;
  width: auto;
}

table#documentazione_tabella {
  border-collapse: separate;
}

table#documentazione_tabella thead tr th {
  padding: 5px 7px 5px 7px;
  border-top: 0;
}

table#documentazione_tabella thead tr th button {
  line-height: 15px;
  width: 100%;
  box-sizing: border-box;
}

table#documentazione_tabella tbody tr {
  background-color: #eee;
}

table#documentazione_tabella tbody tr:hover {
  background-color: #ddd;
}

table#documentazione_tabella tbody tr td {
  padding: 5px 7px 5px 7px;
  border-top: 2px solid #fff;
}

table#documentazione_tabella tbody tr td i {
  font-size: 16px;
}

@media screen and (max-width: 750px) {
  .hide-sm {
    display: none;
  }
}

ul.tree {
  position: relative;
  padding-left: 18px !important;
  list-style: none;
}

ul.tree li {
  margin: 0 !important;
  position: relative;
  outline: 0;
}

ul.tree li::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 14px;
  left: -15px;
  width: 14px;
  height: 0;
  border-top: 1px dotted #67b2dd;
  z-index: 1;
}

ul.tree li.tree-item {
  padding-left: 18px;
}

ul.tree li.tree-item::before {
  width: 32px;
}

ul.tree li .tree-branch-name, ul.tree li .tree-item-name {
  padding: 6px 7px 4px 7px;
}

ul.tree li .tree-branch-name:hover, ul.tree li .tree-item-name:hover {
  background-color: #f2f3f3;
}

ul.tree li button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  display: inline-block;
  vertical-align: middle !important;
}

ul.tree li button.icon-caret {
  margin-right: 2px;
  color: #67b2dd;
}

ul.tree li button.icon-caret::before {
  content: '\f0fe';
}

ul.tree li button .icon-folder::before {
  content: '\f07b';
}

ul.tree li button .icon-check {
  margin-right: 7px !important;
}

ul.tree li button .icon-check::before {
  content: '\f0c8';
  color: #CCC;
}

ul.tree li .tree-branch-select-all-children {
  margin-top: 5px;
  color: #67b2dd;
  font-size: .9em;
  position: relative;
}

ul.tree li .tree-branch-select-all-children::before {
  content: "";
  position: absolute;
  top: -12px;
  bottom: 100%;
  left: 6px;
  display: inline-block;
  z-index: 1;
  border-left: 1px dotted #67b2dd;
}

ul.tree li .tree-branch-select-all-children i.icon-select-all-check {
  margin: 0 5px 0 1px;
  color: #67b2dd;
}

ul.tree li .tree-branch-select-all-children i.icon-select-all-check::before {
  content: '\f0c8';
}

ul.tree li ul {
  padding-left: 24px !important;
  list-style: none;
  position: relative;
}

ul.tree li.tree-open > div > button.icon-caret::before {
  content: '\f146' !important;
}

ul.tree li.tree-open > div > button .icon-folder::before {
  content: '\f07c';
}

ul.tree li.tree-open ul::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: -4px;
  bottom: 15px;
  left: 6px;
  z-index: 1;
  border-left: 1px dotted #67b2dd;
}

ul.tree li.tree-selected > div button .icon-check::before {
  color: #333;
  content: '\f14a';
}

ul.tree li .tree-loader {
  font-style: italic;
  color: #ccc;
}

ul.tree li input[type=checkbox] {
  display: none;
}

ul.tree .tree-branch.tree-all-children-selected > .tree-branch-select-all-children i.icon-select-all-check::before {
  content: '\f14a' !important;
}

.model_selector_container .model_selector_component_wrapper {
  width: 50%;
  padding: 0 5px 5px 0;
  box-sizing: border-box;
  float: left;
}

.model_selector_container .model_selector_component_wrapper .model_selector_component_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100%;
  padding: 13px;
  border: 0;
  border-color: #ddd;
  border-style: solid;
  box-sizing: border-box;
  background-color: #f2f2f2;
  color: #013a6f;
  text-align: left;
  font-weight: 700;
  position: relative;
}

.model_selector_container .model_selector_component_wrapper .model_selector_component_button:hover {
  background-color: #eee;
}

.model_selector_container .model_selector_component_wrapper .model_selector_component_button .model_selector_component_button_caret {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%) rotate(45deg);
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-top: -3px;
  border-right: 2px solid #013a6f;
  border-bottom: 2px solid #013a6f;
}

.model_selector_container .model_selector_component_wrapper.wrapper_for_machine_model_selector .model_selector_component_button {
  background-color: #0095dc;
  border-color: #0095dc;
  color: #fff;
}

.model_selector_container .model_selector_component_wrapper.wrapper_for_machine_model_selector .model_selector_component_button .model_selector_component_button_caret {
  border-color: #fff;
}

.model_selector_container .model_selector_component_wrapper.wrapper_for_machine_model_favorites .model_selector_component_button {
  color: #dfa907;
}

.model_selector_container .model_selector_component_wrapper.wrapper_for_machine_model_favorites .model_selector_component_button i {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 1.6em;
  font-weight: 400;
}

.model_selector_container .model_selector_component_wrapper.wrapper_for_machine_model_favorites .model_selector_component_button .model_selector_component_button_caret {
  border-color: #dfa907;
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container {
  position: relative;
  height: 0;
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  z-index: 11;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown .machine_model_search_bar {
  padding: 5px;
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown .loading_message, .model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown .not_found_message {
  display: none;
  padding: 5px;
  text-align: center;
  font-style: italic;
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown.loading .loading_message {
  display: block;
}

.model_selector_container .model_selector_component_wrapper .model_selector_dropdown_container .model_selector_dropdown.not_found .not_found_message {
  display: block;
}

.model_selector_container .model_selector_component_wrapper.active .model_selector_component_button_caret {
  transform: translateY(-50%) rotate(-135deg);
  margin-top: 3px;
}

.model_selector_container .model_selector_component_wrapper.active .model_selector_dropdown {
  display: block !important;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_button, .model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_unselect {
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_button:hover, .model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_unselect:hover {
  background-color: #eee;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_button {
  width: 100%;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_button i {
  font-weight: 400;
  color: #dfa907;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_button.filtering_by_favorites i {
  font-weight: 900;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_isactive {
  border-bottom: 1px solid #ddd;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
}

.model_selector_container .model_selector_component_wrapper .machine_model_search_favorites_isactive .machine_model_search_favorites_isactive_caption {
  padding: 5px;
}

.model_selector_container ul.machine_model_list {
  list-style: none;
  max-height: 350px;
  overflow-y: auto;
}

.model_selector_container ul.machine_model_list li {
  margin: 0;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
}

.model_selector_container ul.machine_model_list li .machine_model_dropdown_image {
  width: 100px;
  box-sizing: border-box;
  padding: 5px;
}

.model_selector_container ul.machine_model_list li .machine_model_dropdown_image img {
  width: 100%;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list {
  width: calc(100% - 100px);
  padding: 5px;
  box-sizing: border-box;
  align-self: center;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list > .machine_model_group_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  background-color: transparent;
  text-align: left;
  position: relative;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  width: calc(100% - 25px);
  padding: 5px;
  background-color: transparent;
  text-align: left;
  position: relative;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_button i {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #0095dc;
  margin: 0;
  visibility: hidden;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_button i::before {
  content: '\f067';
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_button:hover {
  background-color: #eee;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_button:hover i {
  visibility: visible;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  padding: 5px;
  width: 25px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list:hover {
  background-color: #eee;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list i {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #0095dc;
  vertical-align: center;
  margin-right: 0;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list i::before {
  content: '\f329';
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list.full_width {
  width: 100%;
  text-align: left;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list.full_width i {
  top: 50%;
  right: .5rem;
  transform: translate(0, -50%);
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul {
  display: none;
  padding-left: 10px;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li {
  position: relative;
  padding: 0;
  padding-left: 10px;
  margin: 0;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 0;
  height: 100%;
  border-left: 1px dotted #0095dc;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  width: 8px;
  height: 0;
  border-top: 1px dotted #0095dc;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li:first-child::before {
  height: 50%;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 5px;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  text-align: left;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button:hover {
  background-color: #eee;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_filter_button {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_favorite_extend {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_favorite_extend .machine_model_favorite_extend_label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_favorite i, .model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_favorite_extend i {
  color: #dfa907;
  font-weight: 400;
  margin: 0;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li .machine_model_codes_flex_wrapper button.machine_model_delete_favorite i {
  color: #a94442;
  font-weight: 400;
  margin: 0;
}

.model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li.favorite .machine_model_favorite i, .model_selector_container ul.machine_model_list li .dropdown_machine_model_list ul li.favorite .machine_model_favorite_extend i {
  font-weight: 900 !important;
}

.model_selector_container ul.machine_model_list li.hide {
  display: none;
}

.model_selector_container ul.machine_model_list li.active .dropdown_machine_model_list .machine_model_group_button_flex {
  border-color: #0095dc;
}

.model_selector_container ul.machine_model_list li.active .dropdown_machine_model_list .machine_model_group_button_flex .machine_model_group_open_list i::before {
  content: '\f32c';
}

.model_selector_container ul.machine_model_list li.active .dropdown_machine_model_list ul {
  display: block;
}

.model_selector_container .models_selected_component_wrapper {
  background-color: #f4f4f4;
  width: calc(100% - 5px);
  float: left;
}

.model_selector_container .models_selected_component_wrapper .model_selected_component_title {
  font-size: 16px;
  border-left: 2px solid #013a6f;
  padding: 10px;
}

.model_selector_container .models_selected_component_wrapper .model_selector_dropdown::after {
  content: '';
  display: block;
  clear: both;
}

.model_selector_container .models_selected_component_wrapper ul.machine_model_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
}

.model_selector_container .models_selected_component_wrapper ul.machine_model_list > li {
  margin: 0;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.model_selector_container .models_selected_component_wrapper ul.machine_model_list > li .dropdown_machine_model_list {
  width: 100%;
}

.model_selector_container .models_selected_component_wrapper ul.machine_model_list > li .dropdown_machine_model_list .machine_model_group_button {
  padding: 0 5px;
  border-width: 0;
  font-weight: 700;
}

.model_selector_container::after {
  content: '';
  display: block;
  clear: both;
}

#brands_checklist_container .brand_checkbox_box {
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  border-radius: 5px;
  margin: 0 5px 5px 0;
}

#brands_checklist_container .brand_checkbox_box img.brand_checkbox_logo {
  height: 16px;
  margin-left: 10px;
}

@media screen and (max-width: 750px) {
  .model_selector_container .model_selector_component_wrapper {
    width: 100%;
  }
  .model_selector_container .models_selected_component_wrapper ul.machine_model_list > li {
    width: 100%;
  }
}

.widget-box .widget-title {
  padding: 10px 20px;
  background: #013a6f;
  color: white;
}

.functional_group_picker_box {
  padding: 5px;
  margin: 3px;
  background-color: #eee;
}

.functional_group_picker_box:hover {
  background-color: #ddd;
}

.functional_group_picker_box .funcional_group_picker_image {
  width: 100px;
}

.bulletin_created_by {
  margin: 10px;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: right;
}

.bulletin_created_by .unactive {
  color: #8c8c8c;
}

.bulletin_created_by .deleted {
  font-size: 1rem;
  color: #F44336;
  text-transform: uppercase;
}

#bulletin_handler_languages_stripe > li select.form-control {
  height: auto;
}

#bulletin_handler_languages_stripe > li .bulletin_handler_add_language_button, #bulletin_handler_languages_stripe > li .technical_document_handler_add_language_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  line-height: 30px;
}

#bulletin_handler_languages_stripe > li .bulletin_handler_add_language_button:hover, #bulletin_handler_languages_stripe > li .technical_document_handler_add_language_button:hover {
  background-color: #eee;
}

#bulletin_handler_languages_stripe > li .bulletin_language_delete, #bulletin_handler_languages_stripe > li .technical_document_language_delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  padding: 10px;
  margin: -10px -15px -10px 5px;
  border: 0;
  color: #d9534f;
  border-top-right-radius: 4px;
}

#bulletin_handler_languages_stripe > li .bulletin_language_delete:hover, #bulletin_handler_languages_stripe > li .technical_document_language_delete:hover {
  background-color: #ddd;
}

#bulletin_handler_forms_stripe .tab_bulletin, #bulletin_handler_forms_stripe .tab_technical_document {
  margin: 0;
  border-radius: 5px;
  border: 1px solid #013a6f;
}

#bulletin_handler_forms_stripe .tab_bulletin .bulletin_code_reload, #bulletin_handler_forms_stripe .tab_technical_document .bulletin_code_reload {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  padding: 0 10px 0 10px;
  margin: 0;
  line-height: 31px;
}

#bulletin_handler_forms_stripe .tab_bulletin .bulletin_code_reload:hover, #bulletin_handler_forms_stripe .tab_technical_document .bulletin_code_reload:hover {
  background-color: #eee;
}

#bulletin_handler_forms_stripe .tab_bulletin .bulletin_code_reload i, #bulletin_handler_forms_stripe .tab_technical_document .bulletin_code_reload i {
  margin: 0;
}

#bulletin_handler_forms_stripe .tab_bulletin button.bulletin_button, #bulletin_handler_forms_stripe .tab_technical_document button.bulletin_button {
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  background-color: #f4f4f4;
}

#bulletin_handler_forms_stripe .tab_bulletin button.bulletin_button:hover, #bulletin_handler_forms_stripe .tab_technical_document button.bulletin_button:hover {
  background-color: #eee;
}

.flag-icon-en {
  background-image: url(../flags/4x3/en.svg);
}

.legend-box {
  width: 16px;
  height: 16px;
  border: 1px solid grey;
}

/** Dashboard */
.dashboard-item-link {
  width: 100%;
  display: flex;
}
